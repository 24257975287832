import axios from "axios";
import Swal from "sweetalert2";
import router from "../../router/index";
import store from "../../store/index";

export function clear_input_file() {
  let files = document.querySelectorAll("input[type='file']");
  files.forEach((element) => {
    element.value = "";
  });
}

export function NumbersOnly(evt) {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
}

export let baseURL = "https://pcg-training.api-crud.uz/";

export function disabled(bool) {
  document.querySelectorAll('[type="submit"]').forEach((e) => {
    e.disabled = bool;
  });
}
async function api(endpoint, method, data) {
  store.dispatch("setloading", true);
  let token = store.state.user?.access_token;
  let result = null,
    error = null;
  await axios
    .create({
      baseURL: `${baseURL}${endpoint}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .request({ method: method.toUpperCase(), data: data })
    .then((res) => {
      result = res.data;
      clear_input_file();
    })
    .catch((err) => {
      error = err;
      catchError(err);
    })
    .finally(() => {
      store.dispatch("setloading", false);
    });
  return new Promise((resolve, reject) => {
    if (result) {
      resolve(result);
    } else {
      reject(error);
    }
  });
}

export function success(target) {
  if (target !== undefined) {
    if (typeof target == "number") {
      document.querySelectorAll("[data-dismiss='modal']")[target].click();
    } else if (typeof target == "string") {
      document.querySelector(`[${target}]`).click();
    }
  }
  return Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 800,
    timerProgressBar: true,
  }).fire({
    icon: "success",
    title: "Amaliyot bajarildi",
  });
}

export function catchError(error) {
  if (error.response?.status == 401) {
    console.clear();
    localStorage.clear();
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
      .fire({
        icon: "error",
        title: "Foydalanuvchi nomi yoki parolda xatolik",
      })
      .then(() => {
        if (window.location.pathname == "/") {
          console.log();
        } else {
          window.location.href = "/";
        }
      });
  } else if (error.message == "Network Error") {
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "warning",
      title: "Internet bilan a'loqa mavjud emas",
    });
  } else if (error.response?.status == 422) {
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "warning",
      title: "Ma'lumot to'liq emas",
    });
  } else if (error.response?.status == 404) {
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "error",
      title: "URL manzil notog'ri !",
    });
  } else if (error.response?.status == 400) {
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "error",
      title: error.response?.data.detail,
    });
  }
  console.clear();
}

// form data function
export const getFormData = (object) =>
  Object.entries(object).reduce((fd, [key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
    return fd;
  }, new FormData());

// roles
export function roles() {
  return api(`role`, `GET`);
}

// token
export function token(data) {
  let form_data = new FormData();
  form_data.append("username", data.username);
  form_data.append("password", data.password);
  return api(`token`, `POST`, form_data);
}

export function refresh_token(data) {
  return api(`refresh-token`, `POST`, data);
}

// users
export function users(search, role, id, page, limit, status) {
  return api(
    `users/?id=${id}&page=${page}&limit=${limit}&status=${status}${
      search ? `&search=${search}` : ""
    }${role ? `&role=${role}` : ""}`,
    `GET`
  );
}

export function create_user(data) {
  return api(`users/add`, `POST`, data);
}

export function create_user_customer(data) {
  return api(`users/add_customer`, `POST`, data);
}

export function update_user(data) {
  return api(`users/update`, `PUT`, data);
}

export function delete_user(id) {
  return api(`users/delete/${id}`, "DELETE");
}

// uploaded files
export function uploaded_files(
  id = 0,
  source,
  source_id,
  from_date,
  end_date,
  page,
  limit,
  search = ""
) {
  const date =
    from_date && end_date ? `&from_date=${from_date}&to_date=${end_date}` : "";
  return api(
    `uploaded_files/?id=${id}&source=${source}&source_id=${source_id}&page=${page}&limit=${limit}${date}${
      search ? `&search=${search}` : ""
    }`,
    "GET"
  );
}

export function uploaded_files_create(data) {
  return api(`uploaded_files/create`, "POST", getFormData(data));
}

export function uploaded_files_update(data) {
  return api(`uploaded_files/update`, "PUT", getFormData(data));
}

export function uploaded_files_delete(data) {
  return api("uploaded_files/delete", "DELETE", getFormData(data));
}

export function timer_create(data) {
  return api(`video_timer/add`, "POST", getFormData(data));
}

export function video_timer(data) {
  return api("uploaded_files/video_time", "POST", getFormData(data));
}

// Video

export function video(
  id = 0,
  user_id,
  video_id,
  from_date,
  end_date,
  page,
  limit,
  status
) {
  const date =
    from_date && end_date ? `&from_date=${from_date}&to_date=${end_date}` : "";

  const status_filter = status ? `&status=${status}` : "";
  return api(
    `video/?id=${id}&user_id=${user_id}&video_id=${video_id}&page=${page}&limit=${limit}${status_filter}${date}
		`,
    "GET"
  );
}

export function videos_timer(
  id = 0,
  user_id,
  video_id,
  from_date,
  end_date,
  page,
  limit,
  status
) {
  const date =
    from_date && end_date ? `&from_date=${from_date}&to_date=${end_date}` : "";
  return api(
    `video_timer/?id=${id}&user_id=${user_id}&video_id=${video_id}&page=${page}&limit=${limit}${
      status ? `&status=${status}` : ""
    }${date}
		`,
    "GET"
  );
}

export function video_update(data) {
  return api(`video/update`, "PUT", data);
}

export function second_video() {
  return api(`video/second_video`, "POST");
}
