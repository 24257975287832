import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';

const access_token = localStorage['access_token'];
const role = store.state.user?.role;

const requireAuth = (to, from, next) => {
	let access_token = store.state.user?.access_token;
	if (!access_token) {
		next({ name: 'login' });
		return;
	}
	next();
	return;
};

let routes = [
	{
		path: '/:catchAll(.*)',
		name: 'notFound',
		meta: { title: 'Sahifa Topilmadi' },
		component: () => import('../components/Utils/NotFound.vue'),
	},
	{
		path: '/',
		name: 'login',
		meta: { title: 'Login' },
		component: () => import('../views/Login/Login.vue'),
	},
	{
		path: '/home',
		name: 'home',
		beforeEnter: requireAuth,
		meta: { title: 'Home', role: ['user'] },
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/users',
		name: 'users',
		beforeEnter: requireAuth,
		meta: { title: 'Hodimlar', role: ['admin'] },
		component: () => import('../views/Users/Users.vue'),
	},
	{
		path: '/clients',
		name: 'clients',
		beforeEnter: requireAuth,
		meta: { title: 'Mijozlar', role: ['admin'] },
		component: () => import('../views/Clients/Clients.vue'),
	},
	{
		path: '/videos',
		name: 'videos',
		beforeEnter: requireAuth,
		meta: { title: 'Videolar', role: ['admin'] },
		component: () => import('../views/Settings/videos.vue'),
	},
	{
		path: '/video-history',
		name: 'videos-history',
		beforeEnter: requireAuth,
		meta: { title: 'Videolar tarixi', role: ['user'] },
		component: () => import('../views/Clients/ClientViewHistory.vue'),
	},
	{
		path: '/history',
		name: 'history',
		beforeEnter: requireAuth,
		meta: { title: 'Mijoz tarixi', role: ['admin'] },
		component: () => import('../views/Clients/History.vue'),
	},
	{
		path: '/settings',
		name: 'settings',
		beforeEnter: requireAuth,
		meta: { title: 'Sozlamalar' },
		component: () => import('../views/Settings/SettingMenu.vue'),
	},
];

routes = routes.filter((route) => {
	if (route.meta?.role) {
		return route.meta.role.includes(role);
	} else {
		return route;
	}
});

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: 'active',
});

export default router;
