<template>
  <Login
    v-if="$route.name == 'login'"
    @contextmenu="(e) => e.preventDefault()"
  />
  <div v-if="$route.name !== 'login'" @contextmenu="(e) => e.preventDefault()">
    <Navbar v-if="$route.name != 'notFound'" />
    <div
      id="main-container"
      :style="$route.name == 'notFound' ? 'height: 100vh' : ''"
      class="container-fluid p-2 responsive-y"
    >
      <div
        id="main-card"
        class="p-1 responsive-y"
        :class="$route.name != 'settings' ? 'card shadow p-2' : ''"
      >
        <button
          @click="back()"
          class="btn btn-sm btn-outline-secondary btn-back"
          v-if="$route.meta.iscomponent"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <Router-view />
      </div>
    </div>
  </div>
  <Loading v-if="loading" />
</template>

<script>
import Navbar from "@/components/Utils/Header.vue";
import Login from "./views/Login/Login.vue";
import Loading from "./components/Utils/Loading.vue";
export default {
  name: "App",
  components: { Login, Navbar, Navbar, Loading },
  watch: {
    $route(to) {
      document.title = `PCG - ${to.meta.title}`;
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },
};
</script>

<style>
@import url("./components/Style/main.css");
</style>
