<template>
	<div class="content login">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-sm-12 contents">
					<div class="row justify-content-center">
						<div class="col-md-8">
							<div class="mb-2 text-center">
								<img src="../../assets/brand-primary.svg" width="200" alt="" />
								<h3>Xush kelibsiz !</h3>
								<p class="mb-0">
									{{ content == 'sign-in' ? 'Kirish' : "Ro'yhatdan" }} uchun
									ushbu formani to'ldiring.
								</p>
							</div>
							<div class="row m-0 mb-1">
								<div class="col px-1">
									<button
										class="btn btn-sm w-100"
										:class="
											content != 'sign-in'
												? 'btn-outline-primary'
												: 'btn-primary'
										"
										@click="
											type = false;
											content = 'sign-in';
										"
									>
										Kirish
									</button>
								</div>
								<div class="col px-1">
									<button
										class="btn btn-sm w-100"
										:class="
											content == 'sign-in'
												? 'btn-outline-primary'
												: 'btn-primary'
										"
										@click="
											type = false;
											content = 'sign-up';
										"
									>
										Ro'yhatdan o'tish
									</button>
								</div>
							</div>
							<form @submit.prevent="signin" v-if="content == 'sign-in'">
								<div class="form-group first field--not-empty">
									<label for="username">Telefon</label>
									<div class="input-group flex-nowrap">
										<div class="input-group-text px-1">+998</div>
										<input
											type="tel"
											class="form-control"
											id="username"
											placeholder="Telefon"
											v-model="data.username"
											autocomplete="off"
											minlength="9"
											maxlength="9"
											@keypress="$numbersonly"
											required
										/>
										<div class="input-group-text">
											<i class="fa-solid fa-phone"></i>
										</div>
									</div>
								</div>
								<div class="form-group last mb-4 field--not-empty">
									<label for="password">Parol</label>
									<div class="input-group flex-nowrap">
										<input
											:type="type ? 'password' : 'text'"
											class="form-control"
											id="password"
											placeholder="Parol"
											v-model="data.password"
											autocomplete="off"
											required
										/>
										<div class="input-group-text" @click="type = !type">
											<i :class="`fa-regular fa-eye${type ? '-slash' : ''}`" />
										</div>
									</div>
								</div>
								<button
									type="submit"
									id="login_button"
									class="btn btn-block text-white btn-primary"
									:disabled="!data.username || !data.password"
								>
									<i class="fa-solid fa-arrow-right-to-bracket"></i>
									Kirish
									<div
										class="spinner-border spinner-border-sm mx-1"
										role="status"
										v-if="loading"
									>
										<span class="visually-hidden">Loading...</span>
									</div>
								</button>
							</form>
							<form @submit.prevent="signup" v-else>
								<div class="form-group">
									<label class="fs-6 fw-normal">FIO</label>
									<input
										v-model="body.name"
										type="text"
										class="form-control"
										placeholder="Ism"
										required
									/>
								</div>
								<div class="form-group first field--not-empty">
									<label for="username">Telefon</label>
									<div class="input-group flex-nowrap">
										<div class="input-group-text px-1">+998</div>
										<input
											type="tel"
											class="form-control"
											id="username"
											placeholder="Telefon"
											v-model="body.phone"
											autocomplete="off"
											minlength="9"
											maxlength="9"
											@keypress="$numbersonly"
											required
										/>
										<div class="input-group-text">
											<i class="fa-solid fa-phone"></i>
										</div>
									</div>
								</div>
								<div class="form-group last mb-4 field--not-empty">
									<label for="password">Parol</label>
									<div class="input-group flex-nowrap">
										<input
											:type="type ? 'password' : 'text'"
											class="form-control"
											id="password"
											placeholder="Parol"
											v-model="body.password"
											autocomplete="off"
											required
										/>
										<div class="input-group-text" @click="type = !type">
											<i :class="`fa-regular fa-eye${type ? '-slash' : ''}`" />
										</div>
									</div>
								</div>
								<button
									type="submit"
									id="login_button"
									class="btn btn-block text-white btn-primary"
									:disabled="!body.name || !body.password || !body.phone"
								>
									Ro'yhatdan o'tish
									<div
										class="spinner-border spinner-border-sm mx-1"
										role="status"
										v-if="loading"
									>
										<span class="visually-hidden">Loading...</span>
									</div>
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as api from '@/components/Utils/api';

export default {
	name: 'Login',
	data() {
		return {
			data: {
				username: '',
				password: '',
			},
			type: true,
			loading: false,
			icon: 'fa-regular fa-eye',
			content: 'sign-in',
			body: {
				name: '',
				phone: '',
				password: '',
				role: 'user',
			},
		};
	},
	methods: {
		signin() {
			this.loading = true;
			document.getElementById('login_button').disabled = true;
			api.token(this.data).then((res) => {
				this.requirements(res);
			});
			this.loading = false;
			document.getElementById('login_button').disabled = false;
		},
		requirements(e) {
			this.$store.dispatch('setUser', e);
			window.location = `${e.role == 'user' ? '/home' : '/users'}`;
		},
		signup() {
			this.loading = true;
			document.getElementById('login_button').disabled = true;
			api.create_user_customer(this.body).then((res) => {
				this.body = {
					name: '',
					phone: '',
					password: '',
					role: 'user',
				};
				this.content = 'sign-in';
			});
			this.loading = false;
			document.getElementById('login_button').disabled = false;
		},
	},
	created() {
		localStorage.clear();
	},
};
</script>

<style lang="scss" scoped>
.content {
	overflow-y: auto;
	height: 100vh;
	user-select: none;
	.container {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.row {
		margin: 4rem 0;
	}
	.input-group-text {
		width: 45px;
		background: #fff;
		cursor: pointer;
	}
}
@media (max-width: 768px) {
	.contents .row {
		margin: 0 0;
	}
}
</style>
