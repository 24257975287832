<template>
  <nav class="navbar navbar-expand-lg bg-primary">
    <div class="container-fluid px-4">
      <menu-nav v-if="role == 'admin'" />

      <div class="navbar-brand">
        <img
          class="m-0 ms-3"
          src="../../assets/brand-white.svg"
          height="40"
          alt="MDB Logo"
          loading="lazy"
        />
      </div>
      <div class="d-flex align-items-center">
        <div class="dropdown">
          <a
            class="dropdown-toggle d-flex align-items-center hidden-arrow"
            href="#"
            data-mdb-toggle="modal"
            data-mdb-target="#modal_edit_current_user"
            @click="get_user()"
            ><i class="fa-solid fa-bars text-white"></i>
          </a>
        </div>
        <div class="btn-group shadow-none cursor-pointer">
          <i @click="logout()" class="fa fa-sign-out ms-3 text-white fs-5"> </i>
        </div>
      </div>
    </div>
  </nav>

  <modal id="modal_edit_current_user">
    <template #header>
      <h5 class="modal-title">Ma'lumotlarni o'zgartirish</h5>
      <button type="button" class="btn-close" data-mdb-dismiss="modal"></button>
    </template>
    <template #body>
      <form id="edit-user-form" @submit.prevent="edit()">
        <div class="form-group">
          <label class="fs-6 fw-normal">FIO</label>
          <input
            v-model="body.name"
            type="text"
            class="form-control"
            placeholder="Ism"
            required
          />
        </div>
        <div class="form-group">
          <label class="fs-6 fw-normal">Telefon</label>
          <div class="input-group">
            <span class="input-group-text bg-white">+998</span>
            <input
              v-model="body.phone"
              type="tel"
              minlength="9"
              maxlength="9"
              class="form-control"
              placeholder="Telefon"
              @keypress="$numbersonly"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <label class="fs-6 fw-normal">Parol</label>
          <input
            v-model="body.password_hash"
            type="password"
            class="form-control"
            placeholder="Parol"
            minlength="8"
          />
        </div>
      </form>
    </template>
    <template #footer>
      <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal">
        <i class="fas fa-circle-xmark" />
      </button>
      <button type="submit" class="btn btn-primary" form="edit-user-form">
        <i class="fas fa-circle-check" />
      </button>
    </template>
  </modal>
</template>

<script>
import * as api from "@/components/Utils/api";

import MenuNav from "./MenuNav.vue";
export default {
  components: { MenuNav },
  name: "NavbarItem",
  data() {
    return {
      role: this.$store.state.user?.role,
      routes: [
        {
          title: "Home",
          path: "/home",
          role: ["user"],
        },
        {
          title: "Hodimlar",
          path: "/users",
          role: ["admin"],
        },
        {
          title: "Mijozlar",
          path: "/clients",
          role: ["admin"],
        },
        {
          title: "Sozlamalar",
          path: "/settings",
          role: ["admin"],
        },
        {
          title: "Diagnostika",
          path: "/diagnostic",
          role: ["admin"],
        },
      ],
      body: {
        id: this.$store.state.user?.id,
        name: "",
        phone: "",
        password_hash: "",
        role: "",
        status: true,
      },
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location = "/";
    },
    toggle_menu() {
      this.$store.dispatch("toggleNav", true);
    },
    get_user() {
      api.users("", "", this.body.id, 1, 1, true).then((res) => {
        this.body.name = res.name;
        this.body.phone = res.phone;
        this.body.role = res.role;
      });
    },
    edit() {
      api.update_user(this.body).then(() => {
        api.success().then(() => {
          this.get_user();
        });
      });
    },
  },
};
</script>
